export const classic = { type: 'spring', damping: 26, stiffness: 170 };
export const gentle = { type: 'spring', damping: 14, stiffness: 120 };
export const gentle_slow = { type: 'spring', damping: 14, stiffness: 90 };
export const wobbly = { type: 'spring', damping: 12, stiffness: 180 };
export const stiff = { type: 'spring', damping: 20, stiffness: 210 };
export const slow = { type: 'spring', damping: 60, stiffness: 280 };
export const veryslow = { type: 'spring', damping: 60, stiffness: 140 };
export const molasses = { type: 'spring', damping: 120, stiffness: 280 };
export const dry = { type: 'spring', damping: 35, stiffness: 400 };
// DAMPING = FRICTION
// STIFNESS = TENSION
